const documentMeta = {
    'document.agreement':{
        message: 'Download the Intellectual Property Agreement below and upload it when required information has been fulfilled',
        link: '/download/ipa_greater_favour_app.docx'
    },
    'document.nda':{
        message: 'Download the Non Disclosure Agreement below and upload it when required information has been fulfilled.',
        link: '/download/nda_greater_favoour_app.docx'
    },
    'document.cv':{
        message: 'Please upload your Curriculum Vitae that reflects the attribute you will be enrolling for.',
    },
    'document.waec':{
        message: 'Please upload your original WAEC certificate to become an officer.'
    }
}

export default documentMeta